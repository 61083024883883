import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import useSWR from 'swr'
import { APlayer } from 'aplayer-react'

import 'aplayer/dist/APlayer.min.css'
import './home.css'


const COUNTER_URL = 'https://blizzard-rip-counter.shyrz.workers.dev/count'

const Home = () => {
	const [maxReached, setMaxReached] = useState(false)
	const fetcher = url => fetch(url).then(r => r.json()).then(d => {
		if(d.error === 'max_per_day') {
			setMaxReached(true)
		}
		return d
	})
	const { data, error, isLoading, mutate } = useSWR(COUNTER_URL, fetcher)

	const sacrifice = async (number) => {
		await fetch(COUNTER_URL, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ number }),
		})
		mutate({ count: '正在燃香……' })
	}

	return (
		<div className="home-container">
			<Helmet>
				<title>沉痛悼念 💔 永远怀念</title>
				<meta name="description" content="R.I.P. Blizzard CN & Bobby"/>
				<meta property="og:url" content="https://blizzard.rip"/>
				<meta property="og:type" content="website"/>
				<meta property="og:title" content="沉痛悼念 💔 永远怀念"/>
				<meta property="og:description" content="R.I.P. Blizzard CN & Bobby"/>
				<meta property="og:image" content="https://p191.p3.n0.cdn.getcloudapp.com/items/geuOPKWg/c8af368b-1de7-474a-b30a-defffe23c531.webp"/>
				<meta name="twitter:card" content="summary_large_image"/>
				<meta property="twitter:domain" content="blizzard.rip"/>
				<meta property="twitter:url" content="https://blizzard.rip"/>
				<meta name="twitter:title" content="沉痛悼念 💔 永远怀念"/>
				<meta name="twitter:description" content="R.I.P. Blizzard CN & Bobby"/>
				<meta name="twitter:image" content="https://p191.p3.n0.cdn.getcloudapp.com/items/geuOPKWg/c8af368b-1de7-474a-b30a-defffe23c531.webp"/>
			</Helmet>
			<img
				alt="image"
				src="/playground_assets/bobby_kotick-300w.webp"
				className="image-bobby-kotick"
			/>
			<img
				alt="image"
				src="/playground_assets/blizzard_logo.svg"
				className="image-blizzard-logo"
			/>
			<h2>沉痛悼念 💔 永远怀念</h2>
			<h1>Blizzard CN</h1>
			<h1>Bobby Kotick</h1>
			<p>2008 - 2023</p>
			<h3>R.I.P.</h3>
			<img src="/playground_assets/incense-burner.svg" width='64px' />
			<p>香火：{
				isLoading ? '正在读取' :
					error ? `读取错误` :
					(data ? data.count : '数据错误')
			} { maxReached && '今日上香已达上限' }
			</p>
			<div>
				<button disabled={maxReached} type="secondary" className='button-incense' onClick={() => sacrifice(1)}>
					上 1 炷香
				</button>
				<button disabled={maxReached} type="primary" className='button-incense' onClick={() => sacrifice(6)}>
					上 6 炷香
				</button>
				<button disabled={maxReached} type="secondary" className='button-incense' onClick={() => sacrifice(3)}>
					上 3 柱香
				</button>
			</div>
			<APlayer
				audio={{
					name: 'The Dawn',
					artist: 'Dreamtale',
					url: 'https://p191.p3.n0.cdn.getcloudapp.com/items/P8uNXDrW/5eb8d92d-6959-4e2e-8078-ee0604b3a9f0.mp3',
					cover: '/playground_assets/cover_the_dawn.jpg',
				}}
				autoPlay
				initialLoop="one"
				volume="0.5"
				theme="rgba(93, 113, 152, 0.6)"
			/>
		</div>
	)
}

export default Home
